import React from 'react';
import styled from 'styled-components';
import useBodyLock from '../helpers/useBodyLock';

const StyledWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1001;
`;

const StyledOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1001;
  background: rgba(0,0,0,0.5);
`;

const StyledClose = styled.button`
  background: none;
  border: none;
  position: absolute;
  height: 40px;
  width: 40px;
  padding: 10px;
  top: 10px;
  right: 20px;
  cursor: pointer;
  z-index: 1003;
  &:focus {
    outline: none;
  }
  &:after, &:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 25px;
    background: ${({ theme }) => theme.color.white};
    bottom: 50%;
    right: 0;
    transform-origin: center;
  }
  &:after {transform: rotate(45deg)}
  &:before {transform: rotate(-45deg)}
`;

const StyledImg = styled.img`
  position: absolute;
  max-width: 95%;
  max-height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: scale-down;
  z-index: 1002;
`;

const ExpandedImage = ({ src, onClose }) => {
  useBodyLock();
  return (
    <StyledWrapper onClick={onClose}>
      <StyledImg src={src} onClick={onClose} />
      <StyledClose onClick={onClose} />
      <StyledOverlay onClick={onClose} />
    </StyledWrapper>
  );
};

export default ExpandedImage;
