import React from 'react';
import styled from 'styled-components';
import ImageResize from './ImageResize';

const StyledWrapper = styled.section`
  padding-top: 60px;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  width: 100%;
  max-width: 700px;
  ${({ theme }) => theme.mq.m} {
    grid-row-start: 2;
    padding-top: 10px;
  }
`;

const PhotoGallery = ({ images }) => (
  <StyledWrapper>
    {images.map(image => <ImageResize key={image.id} {...image} />)}
  </StyledWrapper>
);

export default PhotoGallery;
