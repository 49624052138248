import React, {useCallback, useMemo, useState} from 'react';
import Layout from 'components/layout/Layout';
import Grid from 'components/containers/Grid';
import PhotoGallery from 'components/PhotoGallery';
import ModelDetails from 'components/ModelDetails';
import BookForm from 'components/BookForm';
import Container from 'components/containers/Container';
import {graphql} from 'gatsby';
import SEO from "../components/Seo";

const Model = ({data, location}) => {
  const [ isFormOpen, setIsFormOpen ] = useState(false);

  const details = useMemo(() => {
    const {
      height, bust, waist, hips, shoe, name, instagramAccount,
    } = data.datoCmsModelEntity;
    return {
      name,
      instagramAccount,
      sizes: {
        height, bust, waist, hips, shoe,
      },
    };
  }, [data.datoCmsModelEntity]);

  const images = useMemo(() => data.datoCmsModelEntity.images.map(({thumbnail, main, id}) => ( {
    thumbnail: thumbnail.imageFile.childImageSharp.fluid,
    main: main.imageFile.childImageSharp.fluid.originalImg,
    id,
  } )), [data.datoCmsModelEntity]);

  const openForm = useCallback(() => setIsFormOpen(true), [ setIsFormOpen ]);
  const closeForm = useCallback(() => setIsFormOpen(false), [ setIsFormOpen ]);

  return (
    <Layout location={location}>
      <SEO title={details.name ? details.name.toUpperCase() : 'MODEL'} />
      <Container>
        <Grid cols="70% 30%" colsM="1fr" align="start">
          <PhotoGallery images={images}/>
          <ModelDetails openForm={openForm} details={details}/>
        </Grid>
      </Container>
      {isFormOpen && (
        <BookForm closeForm={closeForm} name={details.name}/>
      )}
    </Layout>
  );
};

export default Model;

export const query = graphql`
    query ModelEntityQuery($slug: String!) {
        datoCmsModelEntity(slug: {eq: $slug}) {
            bust
            height
            hips
            name
            waist
            shoe
            instagramAccount
            images {
                id
                thumbnail {
                    imageFile {
                            childImageSharp {
                                fluid {
                                   src
                                    base64
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    presentationHeight
                                    aspectRatio
                                    sizes
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                }
                main {
                    imageFile {
                            childImageSharp {
                                fluid {
                                   originalImg
                                }
                            }
                        }
                }
            }
        }
    }
`;
